import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {};

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  opts,
  theme: {
    themes: {
      light: {
        primary: "#FF9F80",
        secondary: "#F5C484",
        third: "#FFD7A5",
        fourth: "#FEDDAE",
        error: "#b71c1c"
      }
    }
  }
});

import Vue from "vue";
import VueRouter from "vue-router";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "../views/Layout.vue");
const Structure = () =>
  import(/* webpackChunkName: "Structure" */ "../views/Structure.vue");
const Map = () => import(/* webpackChunkName: "Map" */ "../views/Map.vue");
const FAQ = () => import(/* webpackChunkName: "FAQ" */ "../views/FAQ.vue");
const About = () =>
  import(/* webpackChunkName: "About" */ "../views/About.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Structure",
        component: Structure
      },
      {
        path: "/map",
        name: "Map",
        component: Map
      },
      {
        path: "/faq",
        name: "FAQ",
        component: FAQ
      },
      {
        path: "/about",
        name: "About",
        component: About
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
